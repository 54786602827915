<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import { useVModel } from '@vueuse/core';
import { cva, type VariantProps } from 'class-variance-authority';
import { type PrimitiveProps } from 'radix-vue';
import { type HTMLAttributes } from 'vue';

const inputVariants = cva(
	/*tw*/ 'rounded-medium shadow-xs text-md min-w-none flex w-full items-center border focus-within:ring-4 focus-within:ring-opacity-15 focus-within:ring-offset-0',
	{
		variants: {
			size: {
				md: /*tw*/ 'px-2xl py-lg gap-md text-md',
				sm: /*tw*/ 'px-xl py-md gap-md text-sm',
				xs: 'py-sm px-lg text-xs [&_svg]:h-3xl [&_svg]:w-3xl',
				xxs: 'py-xs px-lg text-xxs [&_svg]:h-3xl [&_svg]:w-3xl',
			},
			inputColors: {
				black: 'text-black',
				white: 'text-white',
			},
			disabled: {
				true: 'bg-gray-50 text-gray-500',
			},
			hasError: {
				true: 'border-error-300 focus-within:ring-error-500 [&_svg]:fill-error-500',
				false:
					'border-gray-300 focus-within:ring-gray-400 [&_svg]:fill-gray-500',
			},
			defaultVariants: {
				size: 'md',
				inputColors: 'black',
				type: 'text',
			},
		},
	},
);
export type inputVariants = VariantProps<typeof inputVariants>;

interface Props extends PrimitiveProps {
	size?: inputVariants['size'];
	class?: HTMLAttributes['class'];
	inputColors?: HTMLAttributes['class'];
	modelValue?: string | number;
	type?:
		| 'text'
		| 'number'
		| 'password'
		| 'email'
		| 'date'
		| 'time'
		| 'datetime-local';
	disabled?: boolean;
	maxValue?: number;
	minValue?: string;
	autoFocus?: boolean;
	hideArrows?: boolean;
	hasError?: boolean;
	autoComplete?: string;
	upperCase?: boolean;
}

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
	modelValue: '',
	size: 'md',
	class: '',
	type: 'text',
	disabled: false,
	maxValue: Infinity,
	minValue: '0',
	inputColors: 'black',
	hideArrows: false,
	upperCase: false,
	autoComplete: 'on',
});

const emits = defineEmits<{
	(e: 'update:modelValue', payload: string | number): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
	passive: true,
});

const handleInput = (event: Event) => {
	const newValue = (event.target as HTMLInputElement).value;
	emits(
		'update:modelValue',
		props.upperCase ? newValue.toUpperCase() : newValue,
	);
};
</script>

<template>
	<div
		:class="
			cn(inputVariants({ size, inputColors, hasError, disabled }), props.class)
		"
	>
		<slot name="leftIcon" />
		<input
			v-bind="$attrs"
			v-model="modelValue"
			:disabled="props.disabled"
			:type="props.type"
			:min="minValue ?? '0'"
			:max="maxValue ?? Infinity"
			:class="props.hideArrows && 'hide-arrows'"
			class="w-full grow bg-transparent placeholder-gray-500 focus-visible:outline-none focus-visible:ring-0"
			:autocomplete="props.autoComplete"
			:autofocus="props.autoFocus"
			@input="handleInput"
		/>
		<slot name="rightIcon" />
	</div>
</template>

<style scoped>
.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.hide-arrows {
	-moz-appearance: textfield;
}

.hide-arrows {
	appearance: textfield;
}
/* to prevent ios devices from zooming into input field */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select,
	textarea,
	input {
		font-size: 16px;
	}
}
</style>
